import React from "react"
import styled from "styled-components"
import { Layout, Seo, Nav, Link, Button } from "components/common"

import { device } from "utils"

const ErrorPage = () => (
  <Layout>
    <Seo title="400: Unknown Error" />
    <Nav />

    <Wrap>
      <h1>Uh-oh. Something went wrong</h1>

      <p>
        Please try this action again. If you are still having problems, please
        try one of these pages?
      </p>

      <Link to="/" className="home">
        <Button>Go Home</Button>
      </Link>
    </Wrap>
  </Layout>
)

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: var(--section-mobile-nav-spacing);
  /* margin-top: 140px; */
  padding: 0 var(--sp-24);
  l @media ${device.laptop} {
    margin-top: var(--section-nav-spacing);
  }

  .home {
    margin-top: var(--sp-24);
  }
`

export default ErrorPage
